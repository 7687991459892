import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../layout'

import BackgroundContainer from '../components/image/background-container'
import TitleContainer from '../components/common/title-container'
import Title from '../components/common/title'
import Subtitle from '../components/common/subtitle'
import PageContainer from '../components/common/page-container'
import Link from '../components/common/link'
import DiscreetLink from '../components/common/discreet-link'
import FooterTitle from '../components/common/footer-title'


export default (props) => (
  <Layout title="Our Process">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>Our Process</Title>
      </TitleContainer>
    </BackgroundContainer>

    <PageContainer>
      <h3>Initial consultation</h3>

      <p>Our software projects start off with an initial consulation. We will meet to discuss your requirements and work with you to understand how we can best achieve your vision.</p>

      <h3>Planning</h3>

      <p>
        Once you are satisfied with our initial consulation, we will begin to plan your project. 
        This will include gathering business requirements from key stakeholders. 
        We will then present you with a detailed proposal outlining project phases, development sprints and estimated costs.
      </p>

      <h3>Development</h3>

      <p>
        The development phase will begin once you have signed off the proposal. 
        We usually work in two week development sprints. 
        At the end of each sprint, we like to deploy the work we have completed and give regular updates of our progress.
      </p>

      <p>
        As part of our iterative development process, the product backlog will change while we work on the current sprint. 
        Your priorities will change, and requirements will become clearer as we discuss them in more detail.
      </p>

      <h3>Review and sign-off</h3>

      <p>
        During development, we will create a staging environment so you can preview the changes we make to your project.
        At the end of each development sprint we review the previous sprint, and work with you to agree the next based off the priorities in the product backlog.
      </p>

      <p>
        Once all of the development sprints have been completed, the site will be ready to go live. 
        We will have a final review of the project in order for you to give the go-ahead before deployment.
      </p>

      <h3>Deployment and maintenance</h3>

      <p>
        Once you have signed off the project, we will then deploy your application. 
        We know that this won't be the end - we offer a range of flexible support plans to keep your application ticking over.
        If your application requires any additional functionality once it has been deployed, we'd be more than happy to assist you in this. 
      </p>
    </PageContainer>

    <BackgroundContainer header={props.data.footer}>
      <TitleContainer>
          <FooterTitle>Ready to start your project?</FooterTitle>

          <Link to="/contact" className="btn btn-lg mt-3 btn-primary">
            Get in touch
          </Link>
      </TitleContainer>
    </BackgroundContainer>
  </Layout >
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "process.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footer: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
